import { render, staticRenderFns } from "./prizeDetail.vue?vue&type=template&id=317927c8&scoped=true&"
import script from "./prizeDetail.vue?vue&type=script&lang=js&"
export * from "./prizeDetail.vue?vue&type=script&lang=js&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "317927c8",
  null
  
)

export default component.exports